import { createSignal, For, Show, useContext } from 'solid-js';
import { Heading } from '../../ui-components/heading/heading';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { FilterIcon } from '../icons-library/filter';
import { FilterList } from '../../ui-components/filter-list/filter-list';
import { MobileFilesFilterModal } from './mobile-files-filter-modal';
import { Category, Type } from './document-holder';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { StyledCategoryTagsContainer, StyledFilterIconAndTextWrapper, StyledFilterIconWrapper, StyledMobileFilterButton, StyledMobileFilterButtonContainer } from './files.style';
import { MobileCategoryTag } from '../products/mobile/mobile-category-tag';

export type MobileFilesFilterProps = {
    types: Type[];
    parentCategories: Category[];
    subCategories: Category[];

    selectedTypeId?: number;
    selectedParentCategoriesIds?: number[];
    selectedSubCategoriesIds?: number[];

    clickHandlers: {
        handleTypeClick: (type: Type) => void;
        handleCategoryClick: (category: Category) => void;
        handleSubCategoryClick: (category: Category) => void;
    };

    clearFilters: (e: any) => void;
}


export const MobileFilesFilter = (props: MobileFilesFilterProps) => {
    const { localize } = useContext(AppContext);
    const [mobileFilterViewOpen, setMobileFilterViewOpen] = createSignal<boolean>(false);

    let filterListRef: HTMLDivElement | undefined;

    const openMobileFilterView = () => {
        setMobileFilterViewOpen(true);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'hidden';
    };

    const closeMobileFilterView = () => {
        setMobileFilterViewOpen(false);
        const body = document.getElementsByTagName('body')[0];
        body.style.overflow = 'auto';
    };

    const selectedType = () => props.types.find(type => type.id === props.selectedTypeId);
    const selectedParentCategories = () => props.parentCategories.filter(category => props.selectedParentCategoriesIds?.includes(category.id));
    const selectedSubCategories = () => props.subCategories.filter(category => props.selectedSubCategoriesIds?.includes(category.id));

    return (
        <>
            <FilterList
                isMobile={true}
                isDropdown={true}
                initialOpen={false}
                listHeading={selectedType()?.name 
                    ? localize(selectedType()!.name, selectedType()!.name) 
                    : localize('type', 'Type')
                }
                ref={filterListRef}
            >
                <For each={props.types}>{(type) => (
                    <li>
                        <Radio
                            value={type.uuid}
                            whenClicked={() => {
                                props.clickHandlers.handleTypeClick(type);
                                filterListRef?.click();
                            }}
                            name='type'
                            readableName={localize(type.name, type.name)}
                            isChecked={selectedType()?.id === type.id}
                        />
                    </li>
                )}</For>
            </FilterList>
            
            <Show when={props.parentCategories.length > 0}>
                <StyledMobileFilterButtonContainer onClick={openMobileFilterView}>
                    <StyledMobileFilterButton>
                        <StyledFilterIconAndTextWrapper>
                            <StyledFilterIconWrapper>
                                <FilterIcon />
                            </StyledFilterIconWrapper>
                            <Heading tag='h5' variant='medium' noBlockSpacing={true}>
                                {localize('filters', 'Filters')}
                            </Heading>
                        </StyledFilterIconAndTextWrapper>
                    </StyledMobileFilterButton>
                </StyledMobileFilterButtonContainer>
            </Show>

            <Show when={selectedType()}>
                <StyledCategoryTagsContainer>
                    <MobileCategoryTag
                        category={selectedType()}
                        onClick={(e: any) => props.clearFilters(e)}
                    />
                    <For each={selectedParentCategories()}>{(category: Category) => (
                        <MobileCategoryTag 
                            category={category} 
                            onClick={() => props.clickHandlers.handleCategoryClick(category)}
                        />
                    )}</For>
                    <For each={selectedSubCategories()}>{(subCategory: Category) => (
                        <MobileCategoryTag
                            category={subCategory}
                            onClick={() => props.clickHandlers.handleSubCategoryClick(subCategory)}
                        />
                    )}</For>
                </StyledCategoryTagsContainer>
            </Show>


            <Show when={mobileFilterViewOpen()}>
                <MobileFilesFilterModal
                    closeModal={closeMobileFilterView}
                    applyFiltersText={localize('apply-filters', 'Apply filters')}
                    categoriesHeading={localize('category', 'Category')}
                    subcategoriesHeading={localize('sub-category', 'Sub Category')}

                    parentCategories={props.parentCategories}
                    subCategories={props.subCategories}

                    selectedParentCategories={selectedParentCategories()}
                    selectedSubCategories={selectedSubCategories()}

                    clickHandlers={{
                        handleCategoryClick: props.clickHandlers.handleCategoryClick,
                        handleSubCategoryClick: props.clickHandlers.handleSubCategoryClick,
                    }}
                />
            </Show>
        </>
    );
};
