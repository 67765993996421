import { useLocation } from '@solidjs/router';
import { gql } from 'graphql-request';
import { createEffect, Match, on, Show, Switch, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import theme from '../../style/theme';
import { navigateToAnchor } from '../../tools/scroll-to-element';
import { NotFound } from '../404/404';
import { PageContextProvider } from './page-context-provider';
import { PageStyled } from './page.styles';

import { ErrorCatcher } from '../../tools/error-catcher';
import { PageCacheType } from '../../types/shared';
import { LockedContent } from '../../ui-components/layouts/locked-content';
import { SeoSettings } from '../seo-settings/seo-settings';
import { SidebarPage } from './sidebar-page';
import { ShareAndSave } from '../share-and-save/share-and-save';
import { HCPWarning } from '../hcp-warning/hcp-warning';
import { Text } from '../../ui-components/text/text';
import { Column } from '../../grid-system/column/column';
import { Section } from '../../grid-system/section/section';

export const GET_PAGE_QUERY = gql`
    query GetPage($key: String!) {
        page: resource(key: $key) {
            content
            key
        }
    }
`;

export const PageContainer = () => {

    const { menuStore, setCacheType, createCachedResource, viewport, setStatusCode, userState, pageRegistration, siteInfo } = useContext(AppContext);
    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;
    const location = useLocation();
    const [pageContent] = createCachedResource(GET_PAGE_QUERY, () => ({ key: location.pathname }), true);

    const isHcpPage = () => {
        if (checkHcp()) {
            setCacheType(PageCacheType.none);
            return true;
        }
        setCacheType(PageCacheType.defaultCache);
        return false;
    };

    const isHiddenForPublic = () => {
        return isHcpPage() 
            && !pageContent()?.page?.content?.isVisibleForPublic 
            && !userState.user 
            && pageRegistration.url !== '';
    };

    const sidebarMenu = () => {
        if (pageContent()?.page?.content?.withSidebarMenu) {
            return pageContent()?.page?.content?.sidebarMenu;
        }
        return null;
    };

    const withSidebarMenu = () => {
        if (pageContent()?.page?.content?.withSidebarMenu && pageContent()?.page?.content?.sidebarMenu?.menu) {
            return true;
        }
        return false;
    };

    const hasHero = () => {
        return pageContent()?.page?.content?.blocks?.find((block: { blockName: string }) => {
            if (block.blockName === 'atos/hero') {
                return true;
            }

            if (block.blockName === 'atos/hero-campaign') {
                return true;
            }
        });
    };

    const checkHcp = () => {
        return Boolean(pageContent()?.page?.content?.isHealthcareProfessional);
    };

    const setHcp = () => {
        const isHcp = checkHcp();
        menuStore.setHcp(isHcp);
        return null;
    };

    createEffect(
        on(
            () => location.hash,
            () => navigateToAnchor({
                id: location.hash.replace('#', ''),
                smallScreen: isMobile(),
                isCareSite: siteInfo.siteType === 'atos-care',
            })
        )
    );

    const found = () => {
        if (!pageContent()?.page?.content || pageContent?.loading) {            
            return false;
        }

        return isPagePostType();
    };

    const isPagePostType = () => {
        return pageContent()?.page?.content.type === 'page' || pageContent()?.page?.content.type === 'form';
    };

    if (!pageContent?.loading) {

        if (!pageContent()?.page || !pageContent()?.page?.content) {
            setStatusCode(404);
        }

        if (!isPagePostType()) {
            setStatusCode(404);
        }
    }

    const containsNonShareableBlockTypes = () => {
        /**
         * If there's a certain component on the page, we don't want to show the share and save component.
         * We can also omit the share and save component based on url. This is done inside the component itself.
         * But sometimes there's a component on the page that we don't want to share, but no predefined url slug to omit it.
         */
        const nonShareableTypes = [ 'atos/pdf-builder' ];

        let hasBlock = false;

        if (pageContent().page?.content.blocks === undefined) {
            return false;
        }

        for (const block of pageContent().page.content.blocks) {
            if (block.blockName !== 'next24hr/section') {
                if (nonShareableTypes.includes(block.blockName)) {
                    hasBlock = true;
                }
                continue;
            }

            for (const column of block.blocks) {
                for (const nestedBlock of column.blocks) {
                    if (nonShareableTypes.includes(nestedBlock.blockName)) {
                        hasBlock = true;
                    }
                }
            }
        }

        return hasBlock;
    };

    setHcp();

    createEffect(() => {
        setHcp();
    });

    return (
        <ErrorCatcher componentName="Page">
            <Show when={!pageContent.loading}>
                <PageContextProvider isHcpPage={isHcpPage()} hasHero={hasHero()} withSidebarMenu={withSidebarMenu()} sidebarMenu={sidebarMenu()}>
                    <HCPWarning isHcp={isHcpPage()} />
                    <PageStyled hasHero={hasHero()} isAtosCareSite={siteInfo.siteType === 'atos-care'}>
                        <SeoSettings seo={pageContent().page?.content?.seo} title={pageContent().page?.content?.post_title} />
                        <Switch>
                            <Match when={!found()}>
                                <NotFound />
                            </Match>
                            <Match when={withSidebarMenu()}>
                                <SidebarPage 
                                    content={pageContent().page?.content}
                                    hasHero={hasHero()}
                                    mcRef={pageContent().page?.content.mcRef}
                                />
                            </Match>

                            <Match when={pageContent()?.page?.content}>
                                <DynamicModuleGenerator
                                    content={pageContent().page?.content.blocks}
                                    permalink={pageContent().page?.content.permalink}
                                    pageTitle={pageContent().page?.content.post_title}
                                />
                                <Show when={pageContent().page?.content?.mcRef}>
                                    <Section 
                                        templateShorthand={[ 12 ]}
                                        widthType='bgFull'
                                        isLayoutSection={true}
                                    >
                                        <Column>
                                            <Text fontSize='smaller'>
                                                {pageContent().page?.content.mcRef}
                                            </Text>
                                        </Column>
                                    </Section>
                                </Show>
                            </Match>
                        </Switch>

                        <Show when={!containsNonShareableBlockTypes()}>
                            <ShareAndSave
                                type="page"
                                title={pageContent().page?.content.post_title}
                                permalink={pageContent().page?.content.permalink}
                                data={pageContent().page?.content}
                            />
                        </Show>

                        <Show when={isHiddenForPublic()}>
                            <LockedContent hasHero={hasHero()} />
                        </Show>
                    </PageStyled>
                </PageContextProvider>
            </Show>
        </ErrorCatcher>
    );
};
