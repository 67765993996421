import { createEffect, createSignal, For, onCleanup, Show } from 'solid-js';
import { Section } from '../../grid-system/section/section';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { StyledFlexRow } from '../../ui-components/utility-style-components/flex';
import { CrossIcon } from '../icons-library/cross';
import { Portal } from 'solid-js/web';
import { Category } from './document-holder';
import { StyledHeadingContainer, StyledList, StyledMobileFiltersModal } from './files.style';


type MobileFilesFilterModalProps = {
    closeModal: () => void;
    applyFiltersText: string;
    categoriesHeading: string;
    subcategoriesHeading: string;

    parentCategories: Category[];
    subCategories: Category[];

    selectedParentCategories?: Category[];
    selectedSubCategories?: Category[];

    clickHandlers: {
        handleCategoryClick: (category: Category) => void;
        handleSubCategoryClick: (category: Category) => void;
    };
}

export const MobileFilesFilterModal = (props: MobileFilesFilterModalProps) => {
    const [ inputRef, setInputRef ] = createSignal<HTMLInputElement | undefined>(undefined);

    createEffect(() => {
        if (!inputRef()) return;

        inputRef()!.tabIndex = -1;
        inputRef()!.focus();
        inputRef()!.tabIndex = 0;
    });

    const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.closeModal();
        }
    };

    document.addEventListener('keydown', onKeyPress);

    onCleanup(() => {
        document.removeEventListener('keydown', onKeyPress);
    });
        
    return (
        <Portal mount={document.getElementById('app')!}>
            <StyledMobileFiltersModal onScroll={e => e.preventDefault()}>
                <Section 
                    templateShorthand={[12]}
                    widthType={'bgFull'}
                    backgroundValue={'white'}
                    backgroundType={'color'}
                    heightType={'fill'}
                    customCss='border-radius: 1.07rem;'
                >
                    <div>
                        <StyledFlexRow justifyContent='end'>
                            <CrossIcon size='small' onClick={props.closeModal} />
                        </StyledFlexRow>

                        <StyledHeadingContainer>
                            <Heading noBlockSpacing={true} tag='h3' variant='small'>
                                {props.categoriesHeading}
                            </Heading>
                        </StyledHeadingContainer>
                        <StyledList>
                            <For each={props.parentCategories}>{(category, index) => (
                                <li>
                                    <Checkbox
                                        value={category.uuid}
                                        inputRef={(el: HTMLInputElement) => index() === 0 && setInputRef(el)}
                                        whenClicked={() => props.clickHandlers.handleCategoryClick(category)}
                                        name={category.name}
                                        isChecked={Boolean(props.selectedParentCategories?.includes(category))}
                                    />
                                </li>
                            )}</For>
                        </StyledList>

                        <Show when={props.subCategories?.length > 0}>
                            <StyledHeadingContainer>
                                <Heading noBlockSpacing={true} tag='h3' variant='small'>
                                    {props.subcategoriesHeading}
                                </Heading>
                            </StyledHeadingContainer>
                            <StyledList>
                                <For each={props.subCategories}>{(subcategory) => (
                                    <li>
                                        <Checkbox
                                            value={subcategory.uuid}
                                            whenClicked={() => props.clickHandlers.handleSubCategoryClick(subcategory)}
                                            name={subcategory.name}
                                            isChecked={Boolean(props.selectedSubCategories?.includes(subcategory))}
                                        />
                                    </li>
                                )}</For>
                            </StyledList>
                        </Show>

                        <StyledFlexRow justifyContent='center'>
                            <Button label={props.applyFiltersText} onClick={props.closeModal} />
                        </StyledFlexRow>
                    </div>
                </Section>
            </StyledMobileFiltersModal>
        </Portal>
    );
};